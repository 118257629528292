<template>
  <div class="news_page">
    <div class="banner">
      <img src="@/assets/images/banzhuan/news/banner1.jpg" alt="">
    </div>
    <div class="list">
      <div class="classify">
        <div class="classify_title"><span>产品资讯</span></div>
        <div class="classify_content">
          <div class="item">
            <div class="title">
              羽素喷雾荣获 <br/>
              【2020年新浪美妆口碑榜】<br/>
              年度口碑舒缓修护喷雾<br/>
            </div>
            <div class="img"><img src="@/assets/images/banzhuan/news/news1.jpg" alt=""></div>
            <a class="btn" href="#/award">查看详情</a>
          </div>
          <div class="item">
            <div class="title">
              “因为不满，洞见未来”发布会<br/>
              挑战经典永不止步！
            </div>
            <div class="img"><img src="@/assets/images/banzhuan/news/news2.jpg" alt=""></div>
            <a class="btn" href="https://mp.weixin.qq.com/s?__biz=Mzg2MDE2ODE4OA==&mid=2247484258&idx=1&sn=55b09b923227789576be593ed3b7c1dd&chksm=ce2bc9f1f95c40e715d8983863c968560cd2dd5547027d154fddcf57f331271afd6c27711d92&token=2094383424&lang=zh_CN#rd" target="_blank">查看详情</a>
          </div>
          <div class="item more">
            <div class="title">
              YOU&PURE
            </div>
            <div class="img"><img src="@/assets/images/banzhuan/news/news3.jpg" alt="" style="width:161px;height:164px"></div>
            <div class="tips">更多精彩，敬请期待</div>
          </div>
        </div>
      </div>
      
      <div class="classify">
        <div class="classify_title"><span>品牌资讯</span></div>
        <div class="classify_content">
          <div class="item">
            <div class="title">
              羽素肌肤健康科学研究中心 <br/>
              ×皓雨集团×领域专家<br/>
              三强联手掀科学护肤新浪潮！<br/>
            </div>
            <div class="img"><img src="@/assets/images/banzhuan/news/news41.jpg" alt=""></div>
            <a class="btn" href="https://www.toutiao.com/i6865864390105629192" target="_blank">查看详情</a>
          </div>
          <div class="item">
            <div class="title">
              羽素强势登陆CCTV<br/>
              开启品牌发展新征程!<br/>
            </div>
            <div class="img"><img src="@/assets/images/banzhuan/news/news55.png" alt=""></div>
            <a class="btn" href="https://mp.weixin.qq.com/s?__biz=Mzg2MDE2ODE4OA==&mid=2247485961&idx=1&sn=2e06350db16308ac59e9bb527c1149c5&chksm=ce2bc09af95c498c99a81937490eb054f5cf80edb3563f32d0b3ac8e3c0485c3ce4c9a3567a6&token=62757307&lang=zh_CN#rd" target="_blank">查看详情</a>
          </div>
          <div class="item">
            <div class="title">
              听说，羽素又双叒叕搞了永乐宫？
            </div>
            <div class="img"><img src="@/assets/images/banzhuan/news/news6.jpg" alt=""></div>
            <a class="btn" href="#/yonglegong">查看详情</a>
          </div>
        </div>
      </div>
      <div class="classify">
        <div class="classify_title"><span>活动资讯</span></div>
        <div class="classify_content">
          <!--<div class="item">
            <div class="title">
              悄悄打工的羽素，斩获三连喜讯<br/>
              惊艳了所有人！
            </div>
            <div class="img"><img src="@/assets/images/banzhuan/news/news7.jpg" alt=""></div>
            <a class="btn" href="https://mp.weixin.qq.com/s?__biz=Mzg2MDE2ODE4OA==&mid=2247485219&idx=1&sn=824f159fda16de18fc6b553514f89fac&chksm=ce2bcdb0f95c44a6c9f682f8d8b00964fb21b16bc55c62ddf32bcecb41b5a8261fd9dcd0f019&token=2094383424&lang=zh_CN#rd" target="_blank">查看详情</a>
          </div>-->
          <div class="item">
            <div class="title">
              湖南台《我是大美人》|力荐 <br/>
              祛痘小绿瓶，一款新晋断货王！
            </div>
            <div class="img"><img src="@/assets/images/banzhuan/news/news8.jpg" alt=""></div>
            <a class="btn" href="https://mp.weixin.qq.com/s?__biz=Mzg2MDE2ODE4OA==&mid=2247484793&idx=1&sn=4063d773cf4f271782e7be1be5703f00&chksm=ce2bcfeaf95c46fcec6796663644704239cba725675cc1375c2e6c31639bd09d750fc85a26fa&token=2094383424&lang=zh_CN#rd" target="_blank">查看详情</a>
          </div>
          <div class="item">
            <div class="title">
              《美丽俏佳人》官方力荐 <br/>
              羽素肌肤修护加速器
            </div>
            <div class="img">
              <video src="https://xcxyc.oss-cn-hangzhou.aliyuncs.com/yusu/media/news.mp4" poster="@/assets/images/banzhuan/news/news9.jpg" controls ref="video"></video>
            </div>
            <a class="btn" @click="videoPlay">查看详情</a>
          </div>
           <div  style="display: inline-block; margin-left: 45px;position: absolute;">
            
            <img style="line-height:1;    width: 2.34375rem;" src="@/assets/images/banzhuan/news/C9.png" alt="">
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News',
  components: {
  },
  data:function(){
    return {}
  },
  methods: {
    videoPlay(){
      this.$refs.video.play()
    }
  },
}
</script>
<style scoped lang='scss'>
.news_page{
  .list{
    width: 1445px;
    margin: 0 auto;
    padding-bottom: 200px;
    .classify_title{
      font-size: 24px;
      color: #3e3a39;
      text-align: right;
      line-height: 60px;
      margin-bottom: 70px;
      margin-top: 70px;
      span{
        width: 170px;
        height: 60px;
        text-align: center;
        background: #fff100;
        display: inline-block;
      }
    }
    .title{
      font-size: 20px;
      color: #231815;
      font-weight: 500;
      line-height: 1.5;
      height: 110px;
      padding-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item{
      width: 450px;
      display: inline-block;
      text-align: center;
      margin-left: 45px;
      background: #fff;
      border: 3px solid #fff100;
      box-sizing: border-box;
      box-shadow:0 0 15px rgba(0,0,0,0.2);
      &:nth-child(3n+1){
        margin: 0;
      }
      &.more{
        .title{
          font-size: 30px;
          font-weight: 600;
        }
        .tips{
          margin-bottom: 75px;
          font-size: 24px;
          padding-top: 15px;
        }
        .img{
          &::after{
            display: none;
          }
          &::before{
            display: none;
          }
        }
      }
      .img{
        margin: 40px auto 60px auto;
        position: relative;
        height: 228px;
        display: flex;
        justify-content: center;
        align-items: center;
        &::after{
          content: '';
          position: absolute;
          width: 140px;
          height: 2px;
          background: #b4a08f;
          left: 50%;
          margin-left: -70px;
          top: -20px;
        }
        &::before{
          content: '';
          position: absolute;
          width: 140px;
          height: 2px;
          background: #b4a08f;
          left: 50%;
          margin-left: -70px;
          bottom: -20px;
        }
        img,video{
          width: 400px;
          height: 100%;
          margin: 0 auto;
          display: block;
        }
      }
      .btn{
        color: #6a4220;
        font-size: 24px;
        padding: 15px 30px;
        border:1px solid #9e7a57;
        display: inline-block;
        margin-bottom: 60px;
        cursor: pointer;
      }
    }
  }
}
</style>
